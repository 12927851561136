import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const styleUserRow = (resource: any): string => (resource.is_pedagogical_path ? "table-warning" : "");

export const fetchResources = (
  cohortId: string,
  setCohortResources: Function,
  setHasPedagogicalResource: Function,
  language: string,
  callback?: Function
) => {
  ApiService.get(`cohorts/${cohortId}/resources?order=desc&fetch_author=true`)
    .then((resp) => {
      setCohortResources(resp.data.cohort_resources);
      setHasPedagogicalResource(resp.data.cohort_resources.some((resource: any) => resource.is_pedagogical_path));
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      setCohortResources([]);
      alert(translate(language, "PAGES.COHORT.RESOURCES.ERROR.FETCH"));
    });
};

export const createResource = (
  cohortId: string,
  language: string,
  setSubmitLoading: Function,
  name: string,
  url: string,
  isPublic: boolean,
  isPedagogicalPath: boolean,
  callback?: Function
) => {
  setSubmitLoading(true);
  ApiService.post(`cohorts/${cohortId}/resources`, {
    name,
    url,
    is_public: isPublic,
    is_pedagogical_path: isPedagogicalPath,
  })
    .then((_) => callback?.())
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.RESOURCES.ERROR.CREATE") + error.data.error.message);
    })
    .finally(() => setSubmitLoading(false));
};

export const editResource = (
  cohortId: string,
  resourceId: string,
  language: string,
  setSubmitLoading: Function,
  name: string,
  url: string,
  isPublic: boolean,
  isPedagogicalPath: boolean,
  callback?: Function
) => {
  setSubmitLoading(true);
  ApiService.put(`cohorts/${cohortId}/resources/${resourceId}`, {
    name,
    url,
    is_public: isPublic,
    is_pedagogical_path: isPedagogicalPath,
  })
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.RESOURCES.ERROR.EDIT") + error.data.error.message);
    })
    .finally(() => {
      setSubmitLoading(false);
    });
};

export const deleteResource = (resourceId: string, language: string, callback?: Function) => {
  ApiService.delete(`cohorts/resources/${resourceId}`)
    .then((_) => callback?.())
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.RESOURCES.ERROR.DELETE") + error.data.error.message);
    });
};
