import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const fetchFlags = (language: string, setUniqueFlags: Function, setLoadingFlags: Function) => {
  setLoadingFlags(true);
  ApiService.get("flags")
    .then((resp) => {
      setUniqueFlags(resp.data.flags);
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.USERS.FLAGS.ERROR"));
    })
    .finally(() => {
      setLoadingFlags(false);
    });
};
