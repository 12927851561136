import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const fetchCohortsLeaders = (language: string, setCohortsLeaders: Function, callback?: Function) => {
  ApiService.get("cohorts/leaders")
    .then((resp) => {
      setCohortsLeaders(resp.data.cohort_leaders);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORTS.ERRORS.LOADING_LEADERS"));
    });
};

export const fetchCohortsManagers = (language: string, setCohortsManagers: Function, callback?: Function) => {
  ApiService.get("cohorts/managers")
    .then((resp) => {
      setCohortsManagers(resp.data.cohort_managers);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORTS.ERRORS.LOADING_MANAGERS"));
    });
};

export const getIntensityColor = (intensity: string) => {
  switch (intensity) {
    case "continuous":
      return { bg: "warning", text: "dark" };
    case "bootcamp":
      return { bg: "primary" };
    case "tracks":
      return { bg: "danger" };
    default:
      return { bg: "secondary" };
  }
};

export const generateRemoteUrl = (
  searchQuery: string,
  selectedCohortLeaderFilter: any[],
  selectedProgramManagerFilter: any[]
) => {
  const filters = [];

  if (searchQuery) {
    filters.push(`search=${searchQuery.toLowerCase()}`);
  }
  if (selectedCohortLeaderFilter.length > 0) {
    filters.push(`leaders=${selectedCohortLeaderFilter.join(",")}`);
  }
  if (selectedProgramManagerFilter.length > 0) {
    filters.push(`managers=${selectedProgramManagerFilter.join(",")}`);
  }
  filters.push(`fetch_users_count=true`);

  return `cohorts${filters.length > 0 ? `?${filters.join("&")}` : ""}`;
};
