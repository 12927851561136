import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useSession } from "../../contexts/SessionContext";
import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

const UserSearch = () => {
  const { language } = useSession();
  const navigate = useNavigate();
  const [receivedError, setReceivedError] = useState<boolean | null>(null);
  const params = useParams();

  useEffect(() => {
    ApiService.get(`users/search?attribute=email&value=${params.email}`)
      .then((resp) => navigate(`/user/${resp.data.users[0].id}`))
      .catch((err) => {
        console.error(err);
        setReceivedError(true);
      })
      .finally(() => setReceivedError(false));
  }, [params, language, navigate]);

  return (
    <div className="text-center py-5 blue-font">
      {receivedError !== null ? (
        <p>{translate(language, receivedError ? "PAGES.USER_SEARCH.ERROR" : "PAGES.USER_SEARCH.NOT_FOUND")}</p>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default UserSearch;
