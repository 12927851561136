export const getExamStatusStyle = (examStatus: string) => {
  switch (examStatus) {
    case "success":
      return "success";
    case "fail":
      return "danger";
    case "repass":
      return "warning";
    default:
      return "light";
  }
};

export const downloadEvaluationFile = (evaluationRender: any) => {
  const link = document.createElement("a");
  link.href = evaluationRender.submission_infos.presigned_url;
  link.setAttribute("download", evaluationRender.submission_infos.filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
