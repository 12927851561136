export const statuses = [
  "SUCCESS",
  "REPASS",
  "FAILED",
  "CORRECTION_PENDING",
  "BEING_CORRECTED",
  "PRE_CORRECTION_IN_PROGRESS",
  "PRE_CORRECTED",
  "IN_PROGRESS",
  "CREATED",
];

export const languages = ["fr-FR", "en-US"];

export const format = ["HTML", "NOTEBOOK", "UPLOAD"];

export const getStatusColor = (status: string): string => {
  let statusColor = "";

  switch (status.toLowerCase()) {
    case "failed":
    case "repass":
      statusColor = "text-danger";
      break;
    case "success":
      statusColor = "text-success";
      break;
    case "correction_pending":
    case "pre_corrected":
      statusColor = "text-warning";
      break;
    case "being_corrected":
    case "pre_correction_in_progress":
      statusColor = "text-primary";
      break;
    case "created":
    case "in_progress":
      statusColor = "text-secondary";
      break;
    default:
      statusColor = "";
  }

  return statusColor;
};
